<template>
  <div class="home">
    <div class="bigTitle">知情同意书</div>
    <div class="bigContent">发布日期：2021年11月27日</div>
    <div class="bigContent">生效日期：2020年10月27日</div>
    <!-- <img src="../../assets/images/privacyPolicyTitle.png" alt="" /> -->
    <div class="bigContent">为了给来访者提供透明、安全、平等的咨询环境，促进在咨询前咨询师与来访者之间的有效沟通，来访者需要了解以下建立心理咨询关系将要涉及的一些基本问题。请来访者仔细阅读、充分理解各条款内容，特别是免除或限制责任的条款（以粗体下划线标识）应重点阅读 。如有任何异议，请不要进行后续操作，请在咨询前及时与咨询师沟通商议。
    </div>
    <div class="bigTitle">一、来访者的权利和义务</div>
    <div class="bigContent">1. 以积极的态度对待咨询，坦诚地向咨询师表露自己，不掩饰或伪装。若因为来访者提供不实信息而影响咨询效果，责任不属于咨询师一方。</div>
    <div class="bigContent">2. 成长和改变需要一定的时间，需要持续的投入和努力。</div>
    <div class="bigContent">3. 需与咨询师协商咨询目标，可同咨询师了解咨询方法。</div>
    <div class="bigContent">4. 需尊重咨询设置，且与咨询师在咨询设置的保护下合作。</div>
    <div class="bigContent">5.请按约定的时间前往咨询室，如果迟到，不会因此而延长时间。</div>
    <div class="bigContent">6.如有事情不能如约前来，需提前24小时取消或改期。</div>
    <div class="bigContent">7.语音或视频咨询过程中请保证网络通畅、咨询环境安静，以保证咨询效果。</div>
    <div class="bigContent">8.语音或视频咨询进行中避免有咨询师和来访者以外的人在场，未经允许禁止录音录像，保证咨询私密性。</div>
    <div class="bigTitle"> 二、咨询师的权利和义务</div>
    <div class="bigContent">1. 对咨询内容严格保密，对有关资料妥善保管，不在咨询以外谈论来访者。以下情况属于保密例外，咨询师可突破保密原则：</div>
    <div class="bigContent">（1）来访者出现自我伤害或伤害他人的倾向；</div>
    <div class="bigContent">（2）来访者的问题涉及法律责任；</div>
    <div class="bigContent">（3）咨询师出于更好地帮助来访者的目的，提出个案讨论或申请专业督导。仅限专业场合，同时会将来访者的个人化信息隐去。</div>
    <div class="bigContent">2. 当来访者情况超出咨询师个人能力处理的范畴时，会与来访者沟通原因并在征得来访者同意后及时安排转介。</div>
    <div class="bigContent">3. 避免双重关系，不得接受来访者的礼物，且不在咨询之外与来访者进行咨询性质的交谈。</div>
    <div class="bigContent">4. 应遵守《中华人民共和国精神卫生法》第二十三条：心理咨询人员不得从事心理治疗或者精神障碍的诊断、治疗。心理咨询人员发现接受咨询的人员可能患有精神障碍的，应当建议其到符合本法规定的医疗机构就诊。</div>
    <div class="bigContent">5. 给精神障碍康复期来访者进行心理咨询需得到精神科医生建议辅助心理咨询的医嘱。</div>
    <div class="bigContent">6. 若以提升专业技能力为目的进行督导等工作需要对咨询进行录音或录像，需征得来访者本人的同意。</div>
    <div class="bigContent">7.若咨询师迟到或缺席，来访者有权要求咨询师补齐原定咨询时间，并且退还迟到或缺席时段所对应的咨询费用。</div>
    
    <div class="bigTitle">三、具体事项的约定</div>
    <div class="bigContent">1. 咨访关系建立：
咨访关系是自愿建立的，咨询师和来访者都有建立和解除关系的自由和权利。</div>
    <div class="bigContent">2. 咨询费用支付：
咨询费用按照咨询师个人主页显示语音/视频/面对面咨询价格收取，每次咨询时长50分钟。在咨询开始前，必须保证已经支付本次咨询费用。</div>
    <div class="bigContent">3．时间延误处置：
咨询一经预约，咨询师将与来访者协商确定合适的咨询时间和地点。来访者需根据约定的时间，准时赴约并确保自己在约定的时间内处于一个合适心理咨询的状态。如遇到特殊情况需取消、变更预约，来访者请按照咨询师个人主页里预约须知中针对 预约变更 以及 爽约/迟到 的规则处理。若咨询师个人主页中没有提供预约变更以及爽约/迟到的相关规则，则默认来访者至少需提前24小时，联系咨询师告知需要变更或取消预约，双方协商处理。否则，咨询会按照约定时间开始。</div>
    <div class="bigContent">4．心理危机免责：
咨询师不提供危机干预服务。如来访者咨询前有过自伤、自杀或伤害他人倾向与行为，咨询师会尽所能帮助，但不能保证心理咨询期间来访者的冲动会减缓，也不能保证来访者今后不再有自伤、自杀或伤害他人的行为与倾向。如果咨询期间出现危机事件，请来访者本人或者监护人致电120、110，以及各地精神卫生中心等机构热线（北京安定医院咨询热线010-58303000；上海精神卫生中心热线12320转5）。但如果来访者最终选择自伤、自杀或伤害他人，作为一个完全民事与刑事行为人，来访者将承担全部责任与行为后果；若为不完全民事与刑事行为人，来访者的监护人承担全部责任与行为后果，心理咨询师不承担任何连带民事或刑事责任。</div>
    <div class="bigContent">5．心理咨询准备：
心理咨询效果有待于心理咨询师和来访者的共同努力，心理咨询是一个促使个人心灵成长的过程，可能也会带来一定的痛苦和不适感。所以，需要系统心理咨询的来访者，需要在时间、心理等方面都有相应的准备。</div>
    <div class="bigContent">6. 紧急情况联系人：
来访者需提供除本人之外的一位联系人的姓名和联系方式，并保证所提供的信息的有效性；如信息不实，咨询师有权拒绝咨询，由此导致的一切后果由来访者承担。</div>
    <div class="bigContent">7. 关于私信：
平台的私信功能，仅用于来访者与咨询师沟通预约时间、地点等事务性内容。因咨询师工作时间所限，私信留言不能确保被及时阅读和回复，咨询师对非事务性留言不保证在私信中给予回复和干预。</div>
    <div class="bigContent">8. 关于社交媒体的使用：
咨询师和来访者在没有必要的情况下互不添加为好友，互不开放社交媒体中有关个人生活的动态展示（如微博、微信朋友圈、QQ空间等），以保护来访者隐私不会在其不知情、不允许的情况被咨询师看到；同时也避免咨询师的空间因为对来访开放而造成不必要的双重关系（如咨询师做培训，来访者在咨询期间参加，成为培训师与学员关系等）。</div>
    <div class="bigContent">9. 咨询的局限性：
心理咨询师不同于专业医疗机构的心理科/精神科医生，心理咨询师为来访者提供的是心理咨询服务。如果来访者需要服用药物，请咨询专业医疗机构的心理科/精神科医生。心理咨询师不会在药物使用的问题上提供专业指导。{{appName}}上的心理咨询不属于疾病诊断或治疗活动，不开展诊疗活动，不属于《医疗机构管理条例》的医疗机构。来访者有义务提供专业医疗机构做出的精神疾病相关诊断，不能隐瞒精神病史，有义务按照咨询师要求到专业医疗机构进行精神疾病鉴别诊断，在精神科医生指导下通过心理咨询进行辅助治疗。</div>
    <div class="bigContent">10. 如来访者未满18周岁，须承诺其监护人了解本协议所有内容并同意本次咨询的发生。</div>
    <div class="bigContent">11. 本协议在来访者勾选同意且支付完成后生效。在来访者和咨询师的咨访关系终止时，本协议终止。本协议未尽事宜，经来访者和咨询师协商后另行签署。签署前请确保咨访双方认真核对所有条款。</div>
    <div class="bigContent">12. 请咨访双方确保在咨询前已经认真阅读，并且承诺在咨询过程中会履行相应的责任和义务。{{appName}}有权根据需要不时地制定、修改本协议和/或各类规则，如有任何变更，{{appName}}将在网站、APP等以公示的方式进行公告，不再单独通知每个用户。变更后的协议和规则一经公布即自动生效，成为本协议的一部分。如用户不同意相关变更，应当立即以书面通知的方式终止本协议；任何修订和新规则一经在网站上公布即自动生效，成为本协议的一部分。如用户登陆或继续使用服务的，则视为对修改后的协议和规则不持异议并同意遵守。除另行明确声明外，任何使服务范围扩大或功能增强的新内容均受本协议约束。</div>
    <div class="bigContent">13. 本协议之解释与适用，以及与本协议有关的争议，均应依照中华人民共和国法律予以处理，并以广州市天河区人民法院为管辖法院。</div>
    <div class="bigContent">14.如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余部分则仍具有法律效力。</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      appName: "",
      companyName: "",
    };
  },
  created () {
    this.appName = this.$route.query.aName
    this.companyName = this.$route.query.cName
  }
};
</script>
<style lang="scss" scoped>
.home{
  box-sizing: border-box;
  padding: .3rem;
  background-color: #f7f7f7; 
  img{
    width: 1.28rem;
    height: .36rem;
  }
  .bigTitle{
    font-size: .28rem;
    color: #333;
    font-weight: bold;
    padding: .3rem 0 0;
  }
  .bigContent{
    font-size: .28rem;
      color: #4d4d4d;
      padding: .2rem 0 0;
      line-height: .4rem;
  }
}
</style>
